#s1 img {
  display: block;
  margin: auto;
  width: 30%;
  min-width: 300px;
  margin-left: 3rem;
  filter: grayscale(1);
  border-radius: 15px;
  box-shadow: 2px 2px 7px #00386b;
}


@media (min-width: 768px) {
  #s1 {
    display: flex;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 25% max-content;
  }

  #s1 img {
    margin-top: 9rem;;
  }
}