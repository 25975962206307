@font-face {
  font-family: Poppins;
  src: url('Fonts/Poppins-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Poppins;
  src: url('Fonts/Poppins-ExtraBold.ttf');
  font-weight: bold;
}

* {
  box-sizing: border-box;
  font-family: Poppins;
}

body {
  margin: 0;
}

ul {
 list-style: none;
}

a {
 text-decoration: none;
}

section {
  margin: 0rem 4rem;
}

section a {
  color: #e70a3a;
}

section h1 {
  color: #00386b;
  text-shadow: 3px 3px 2px #a6adf0;
  border-bottom: 4px solid #e70a3a;
  width: fit-content; /* omezení podrtržení jen na délku nadpisu */
  font-size: 2.5rem;
  text-transform: uppercase;
}

section h2 {
  color: #e70a3a;
  font-weight: bold;
  text-shadow: 3px 3px 2px #f39eb3;
  margin-bottom: 0;
  margin-top: 3rem;
}

section h2 a {
  text-shadow: 3px 3px 7px #818181;
}

section h3 {
  color: #00386b;
  text-shadow: 2px 2px 2px #a6adf0;
  font-weight: normal;
}