.footer {
  background-color: #693896;
  color: gold;
  position: relative;
  bottom: 0;
  text-align: center;
  padding: 1rem 1rem 0.5rem 1rem;
  clip-path: polygon(0 2rem, 100% 0, 100% 100%, 0 100%);
}

@media (min-width: 768px) {
  .footer {
    bottom: -7.8rem;
  }
}