.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: red;
}

.errorCard {
  display: grid;
  grid-template-columns: 1 1fr;
  justify-content: center;
  color: red;
  background-color: white;
  margin: 10rem 3rem;
  padding: 5rem 3rem;
  box-shadow: 2px 2px 7px #00386b;
}

.errorCard a {
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin-top: 4rem;
  color: rgb(68, 0, 255);
}
