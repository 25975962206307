.reference_article, .reference_article_certificates {
  display: grid;
  grid-template-columns: 1fr;
}

.reference_article img, .reference_article_certificates img {
  cursor: pointer;
  margin-bottom: 2rem;
  box-shadow: 2px 2px 7px #00386b;
  width: 150px;
}

.reference_h2 {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 150px
}

@media (min-width: 768px) {
  .reference_article {
    grid-template-columns: 200px 200px 200px;
    align-items: center;
  }

  .reference_article_certificates {
    grid-template-columns: repeat(4, 200px);
    align-items: center;
  }

  .reference_h2 {
    grid-template-columns: 200px 200px;
    justify-content: space-around;
  }
}
