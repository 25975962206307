.list_item {
  display: flex;
  flex-direction: column;
  margin-left: -2.5rem;
  width: fit-content;
  margin: 0;
}

.list_item img {
  width: 80%;
  max-width: fit-content;
  margin: 1rem 1rem 1rem 2rem;
}

.list_item p {
  margin: 0 1rem 5rem 2rem;
}

.list_item h3 {
  margin: 1rem 0rem 0.5rem 2rem;
}